.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-file{
  background-color: bisque;
}

.form-file input,select{
  margin: 1em;
}

.graphic{
  background-color: aliceblue;
  padding: 20px 0px;
}

.breadcrumb-path{
  cursor: pointer;
}

.breadcrumb-path +.breadcrumb-path::before {
  content: " >> ";
  padding: 0em 0.5em
}

.breadcrumb-path:hover{
  color: #0d6efd;
}

.solution-link{
  text-decoration: none;
}

.d3-tree-text{
  text-decoration: none;
  cursor: pointer;
}

.d3-tree-text:hover{
  color: #0d6efd
}

.offcanvas-bottom{
  height: 50vh !important;
}

.buttons-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.outline-brand{
  padding: 0.5em 1em;
  border: 1px solid #0176d3;
  border-radius: 0.25rem;
  color: #0176d3;
  cursor: pointer;
  text-align: center;
}

.outline-brand:hover, .outline-brand:focus{
  color: #014486
}

.outline-brand.solution-others{
  margin-left: 40px;
}